@import "../abstracts/variables";

.table-wrapper {
  td.ant-table-column-sort {
    background: transparent;
  }

  .ant-table-container {
    &::before,
    &::after {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      box-shadow: none !important;
    }

    table {
      border-spacing: 0 16px;
      color: $midnight;

      .ant-typography {
        font-weight: 600;
      }

      thead {
        tr {
          th {
            background-color: $gray4;
            color: $midnight;
            border-bottom: none;
          }
        }
      }
    }

    .ant-table-row {
      .padding-cell {
        padding: 0;
        width: 24px;
      }

      td {
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
        margin-bottom: 16px;

        &:first-child,
        &:last-child {
          border-top: none;
          border-bottom: none;
        }

        &:nth-child(2) {
          border-left: 1px solid $border;
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }

        &:nth-last-child(2) {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          border-right: 1px solid $border;
        }
      }
    }
  }

  .ant-pagination {
    margin-bottom: 24px;
    margin-right: 32px;

    .ant-pagination-total-text {
      position: absolute;
      left: 32px;
    }

    .ant-pagination-prev a {
      border-radius: 8px;
    }

    .ant-pagination-item {
      border: none;
      font-weight: bold;
      border-radius: 8px;
      background-color: rgba($color: $gray5, $alpha: 0.1);

      &-link {
        border: none;
        border-radius: 8px;
        background-color: rgba($color: $gray5, $alpha: 0.1);
      }

      &-active {
        background-color: rgba($color: $primary, $alpha: 0.2);

        a {
          color: $default;
        }
      }
    }

    .ant-pagination-next a {
      border-radius: 8px;
    }

    .ant-pagination-options {
      .ant-select-selector {
        border-radius: 8px;
        height: auto !important;
        line-height: normal !important;
      }
    }
  }
}

.ant-table-content {
  overflow: auto hidden !important;
}

// drag table
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

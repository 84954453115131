$down-icon: "https://komi-assets.s3.amazonaws.com/icons/down.svg";

.ant-form {
  .ant-form-item {
    margin-bottom: 24px;
    width: 100%;
  }

  .ant-form-item-control {
    input,
    textarea {
      background-color: $white;
      border: 1px solid $inputBorder;
      box-sizing: border-box;
      border-radius: 8px;
      height: 40px;
    }
    .ant-input-lg {
      height: 48px !important;
      font-size: $small;
      font-weight: $regular;
    }
    .ant-form-item-control-input-content {
      height: 100%;
    }
  }

  .ant-select-single {
    .ant-select-selector {
      background-color: $white;
      border: 1px solid $inputBorder;
      box-sizing: border-box;
      border-radius: 8px !important;
      height: 40px !important;
      line-height: 40px !important;

      .ant-select-selection-item {
        line-height: 40px !important;
      }

      .ant-select-selection-search {
        input {
          height: 40px !important;
          line-height: 40px !important;
        }
      }

      .ant-select-selection-placeholder {
        line-height: 40px !important;
      }
    }
  }
  .ant-select-lg {
    .ant-select-selector {
      height: 48px !important;
      .ant-select-selection-item {
        line-height: 46px !important;
        font-weight: $medium;
      }
    }
    .ant-select-arrow {
      top: 42%;
      right: 22px;
      .anticon-down {
        background: url($down-icon);
        width: 24px;
        height: 24px;
        svg {
          display: none;
        }
      }
    }
  }
}

.ant-input-search {
  width: 345px;
  padding: 8px 0 8px 16px;

  input {
    height: 40px;
  }

  &-icon {
    padding-right: 16px;

    &::before {
      display: none;
    }
  }
}

.media-item {
  height: 252px;
  margin-bottom: 24px;

  .ant-upload.ant-upload-select-picture-card {
    display: block;
    width: 100%;
    height: 252px;
    margin: 0;
    border-radius: 16px;
    background-color: $white;

    .ant-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0;
    }
  }

  .ant-upload--has-data {
    .ant-upload {
      border: none;
    }
  }

  .preview-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    &__btn-actions {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      height: 100%;
      background-color: rgba($color: $default, $alpha: 0.6);
      border-radius: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &--mobile {
        display: none;
        position: absolute;
        bottom: 0;
        background-color: rgba($color: $default, $alpha: 0.4);
        border-radius: 0 0 16px 16px;
        z-index: 99;
        width: 100%;
        height: 32px;

        span {
          font-size: 12px;
          color: $white;
          font-weight: 500;
          line-height: 32px;
        }
      }

      button {
        width: 90px;
        background-color: transparent;
      }

      &--change {
        span {
          color: $white;
        }

        &:hover {
          border-color: $white;
        }
      }

      &--delete {
        border-color: $red;

        span {
          color: $red;
        }
      }
    }

    &:hover {
      .preview-wrapper__btn-actions {
        animation: fade-in 0.3s;
        display: flex;
      }
    }

    img {
      width: 100%;
      border-radius: 16px;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }

    .btn-play-video {
      position: absolute;
      width: 48px;
      height: 48px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;

  video {
    background-color: $default;
    border-radius: 16px;
  }
}

.btn-play-video {
  position: absolute;
  top: 50%;
  left: 50%;
}

.ant-checkbox {
  &-inner {
    border-radius: 4px !important;
  }

  &:hover,
  &:active,
  &:focus {
    .ant-checkbox-inner {
      border-color: $checkedBorder !important;
    }
  }

  &-checked {
    .ant-checkbox-inner {
      border-color: $checkedBorder !important;
      background: $checked;
    }
    &::after {
      border-color: $checkedBorder !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .media-item {
    height: 214px;

    .ant-upload.ant-upload-select-picture-card {
      height: 214px;
    }

    .preview-wrapper {
      &__btn-actions {
        display: none !important;

        &--mobile {
          display: block;
        }
      }
    }
  }
}

@import "../abstracts/variables";

.page-header-wrapper {
  margin-bottom: 36px;
  height: 40px;
}

.page-content-wrapper {
  background: $white;
  border-radius: 20px;
  padding: 24px;
  height: calc(100vh - 112px - 36px);
  overflow: auto;
}

@import "../../styles/abstracts/variables";

aside.ant-layout-sider {
  background: $white;
  padding-top: 34px;

  .ant-menu {
    border: none;
    font-size: 14px;

    &-item {
      width: 200px;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 8px 12px !important;
      border-radius: 8px;
      margin: 18px 0 0 !important;

      &:first-child {
        margin: 0 !important;
      }

      svg path {
        stroke: $default;
      }

      a {
        font-weight: normal;
      }

      &-selected {
        background-color: rgba($primary, 0.2) !important;

        a {
          font-weight: 600;
        }
      }

      &-active,
      &-selected {
        color: $default;

        svg path {
          stroke: $default;
        }

        a {
          color: $default;
        }
      }

      &-active {
        font-weight: 600;

        a {
          font-weight: 600;
        }
      }

      &::after {
        border: none !important;
      }
    }

    &-submenu {
      margin-top: 18px;
      padding: 0;

      &-icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        transition:
          transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
          -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &-arrow {
        display: none;
      }

      &-active,
      &-selected {
        color: $default;

        svg path {
          stroke: $default;
        }

        a {
          color: $default;
        }

        i::before,
        i::after {
          background: $default !important;
        }
      }

      &-title {
        width: 200px;
        padding: 0 0 0 12px !important;
        margin: 0;

        &:hover {
          color: $default;
        }
      }

      &-open,
      &-active {
        .ant-menu-submenu-title {
          font-weight: 600;
        }
      }

      &-open {
        .ant-menu-submenu-icon {
          transform: rotate(90deg) translateX(-50%);
        }
      }
    }

    .ant-menu-sub {
      background-color: transparent !important;

      .ant-menu-item {
        padding-left: 20px !important;

        &:first-child {
          margin-top: 18px !important;
        }
      }
    }
  }

  .sidebar-wrapper {
    min-height: 100%;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 0 12px;

    .profile-info {
      border: 1px solid $border;
      border-radius: 12px;
      padding: 12px;

      .user-role-name {
        text-transform: capitalize;
        font-weight: $regular;
        opacity: 0.8;
      }

      &__name {
        white-space: normal;
        display: block;
        text-align: left;
        font-weight: $semi-bold;
      }
    }
  }
}

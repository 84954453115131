// Text Color
.text {
  font-weight: $regular;
  font-size: $small;
  line-height: 20px;
  color: $text;

  &--regular12.ant-typography {
    font-weight: $regular !important;
    font-size: $x-small !important;
    line-height: 20px;
  }
  &--regular14.ant-typography {
    font-weight: $regular !important;
    font-size: $small !important;
    line-height: 20px;
  }
  &--regular16.ant-typography {
    font-weight: $regular !important;
    font-size: $medium1 !important;
    line-height: 24px;
  }
  &--regular20.ant-typography {
    font-weight: $regular !important;
    font-size: $normal !important;
    line-height: 32px;
  }
  &--medium14.ant-typography {
    font-weight: $medium !important;
    font-size: $small !important;
    line-height: 24px;
  }
  &--medium16.ant-typography {
    font-weight: $medium !important;
    font-size: $medium1 !important;
    line-height: 24px;
  }
  &--medium18.ant-typography {
    font-weight: $medium !important;
    font-size: $medium2 !important;
    line-height: 28px;
  }
  &--semibold12.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $x-small !important;
    line-height: 16px;
  }
  &--semibold14.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $small !important;
    line-height: 20px;
  }
  &--semibold16.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $medium1 !important;
    line-height: 24px;
  }
  &--semibold18.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $medium2 !important;
    line-height: 28px;
  }
  &--semibold20.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $normal !important;
    line-height: 36px;
  }
  &--semibold24.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $normal2 !important;
    line-height: 32px;
  }
  &--bold16.ant-typography {
    font-weight: $bold !important;
    font-size: $medium1 !important;
    line-height: 24px;
  }
  &--bold18.ant-typography {
    font-weight: $bold !important;
    font-size: $medium2 !important;
    line-height: 24px;
  }
  &--bold20.ant-typography {
    font-weight: $bold !important;
    font-size: $normal !important;
    line-height: 32px;
  }
  &--bold24.ant-typography {
    font-weight: $bold !important;
    font-size: $normal2 !important;
    line-height: 36px;
  }
  &--bold32.ant-typography {
    font-weight: $bold !important;
    font-size: $large !important;
    line-height: 48px;
  }
  &--bold40.ant-typography {
    font-weight: $bold !important;
    font-size: $x-large !important;
    line-height: 60px;
  }
  &--bold56.ant-typography {
    font-weight: $bold !important;
    font-size: $xx-large !important;
    line-height: 84px;
  }
  &--bold72.ant-typography {
    font-weight: $bold !important;
    font-size: $xxl-large !important;
    line-height: 108px;
  }

  &--primary {
    color: $primary !important;

    path {
      stroke: $primary !important;
    }
  }

  &--white {
    color: $white !important;

    path {
      stroke: $white !important;
    }
  }

  &--blue {
    color: $blue !important;

    path {
      stroke: $blue !important;
    }
  }

  &--red {
    color: $red !important;

    path {
      stroke: $red !important;
    }
  }
  &--green {
    color: $green !important;

    path {
      stroke: $green !important;
    }
  }
  &--gray5 {
    color: $gray5 !important;

    path {
      stroke: $gray5 !important;
    }
  }
}

.font__size {
  &--xsmall {
    font-size: $x-small !important;
  }

  &--small {
    font-size: $small !important;
  }

  &--medium {
    font-size: $medium1 !important;
  }

  &--medium2 {
    font-size: $medium2 !important;
  }

  &--normal {
    font-size: $normal !important;
  }

  &--large {
    font-size: $large !important;
  }

  &--xlarge {
    font-size: $x-large !important;
  }
  &--xxlarge {
    font-size: $xx-large;
  }
}

// Font Weight
.font__weight {
  &--regular {
    font-weight: $regular !important;
  }
  &--semi-bold {
    font-weight: $semi-bold !important;
  }
  &--bold {
    font-weight: $bold !important;
  }
  &--extra-bold {
    font-weight: $extra-bold !important;
  }
}

.capitalize {
  text-transform: capitalize;
}

.text--center {
  text-align: center !important;
}

.text--left {
  text-align: left !important;
}

.text__align {
  &--left {
    text-align: left !important;
  }

  &--center {
    text-align: center !important;
  }

  &--right {
    text-align: right !important;
  }
}

$fonts-url: "https://komi-assets.s3.amazonaws.com";
$ico-moon-url: "#{$fonts-url}/IcoMoon";

@font-face {
  font-family: 'icomoon';
  src: url("#{$ico-moon-url}/icomoon.eot?7phqem");
  src:
    url("#{$ico-moon-url}/icomoon.eot?7phqem#iefix") format("embedded-opentype"),
    url("#{$ico-moon-url}/icomoon.ttf?7phqem") format("truetype"),
    url("#{$ico-moon-url}/icomoon.woff?7phqem") format("woff"),
    url("#{$ico-moon-url}/icomoon.svg?7phqem#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check .path1::before {
  content: "\e91d";
  color: rgb(61, 213, 152);
}
.icon-check .path2::before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-uncheck::before {
  content: "\e91f";
}
.icon-unbookmark::before {
  content: "\e900";
}
.icon-upload::before {
  content: "\e901";
}
.icon-trash::before {
  content: "\e902";
}
.icon-settings::before {
  content: "\e903";
}
.icon-see::before {
  content: "\e904";
}
.icon-search::before {
  content: "\e905";
}
.icon-schedule::before {
  content: "\e906";
}
.icon-refresh::before {
  content: "\e907";
}
.icon-projects::before {
  content: "\e908";
}
.icon-plus::before {
  content: "\e909";
}
.icon-play::before {
  content: "\e90a";
}
.icon-more::before {
  content: "\e90b";
}
.icon-messages::before {
  content: "\e90c";
}
.icon-logo::before {
  content: "\e90d";
}
.icon-forward::before {
  content: "\e90e";
}
.icon-filter::before {
  content: "\e90f";
}
.icon-edit::before {
  content: "\e910";
}
.icon-download::before {
  content: "\e911";
}
.icon-dashboard::before {
  content: "\e912";
}
.icon-close::before {
  content: "\e913";
}
.icon-chevron_left::before {
  content: "\e914";
  color: #92929d;
}
.icon-chevron_right::before {
  content: "\e915";
}
.icon-chevron_up::before {
  content: "\e916";
}
.icon-chevron_down::before {
  content: "\e917";
}
.icon-camera::before {
  content: "\e918";
}
.icon-bookmark::before {
  content: "\e919";
  color: #ff974a;
}
.icon-attachment::before {
  content: "\e91a";
}
.icon-archive::before {
  content: "\e91b";
}
.icon-activity::before {
  content: "\e91c";
}

@import "../../styles/abstracts/variables";

.dashboard-layout {
  margin-left: 250px;

  .ant-layout-content {
    height: 100vh;
    overflow-y: scroll;
    background-color: $background;
    padding: 36px;
  }

  .filter-wrapper {
    font-size: 16px;
    padding: 32px;

    .ant-dropdown-link {
      font-weight: bold;
    }
  }
}

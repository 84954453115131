$default: #020025;
$dark: #121131;
$primary: #73e5ff;
$blue: #2f80ed;
$blue2: #5e64ff;
$white: #ffffff;
$black: #000000;
$gray: #e6e7e9;
$gray2: #b5b5be;
$gray3: #92929d;
$gray4: #fafafb;
$gray5: #8181a5;
$grey4: #636363;
$karry: #ffe5d2;
$karry2: #fff5ed;
$orange: #ff974a;
$red: #eb5757;
$red-light: #ff4040;
$border: #ececf2;
$inputBorder: #e4e4ed;
$midnight: #011844;
$green: #50c32c;
$text: #011844;
$grey5: #f5f5f5;
$grey6: #cdcdcd;
$magenta: #cd5ce0;
$background: #f5f5fa;
$checked: linear-gradient(360deg, #34aa44 2.22%, #38b249 100%);
$checkedBorder: #2d9c3c;
$grey3: #e0e0e0;
$x-small: 12px;
$small: 14px;
$medium1: 16px;
$medium2: 18px;
$normal: 20px;
$normal2: 24px;
$large: 32px;
$x-large: 40px;
$xx-large: 56px;
$xxl-large: 72px;

$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;

$container: 616px;

$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.ant-modal {
  &-content {
    border-radius: 16px !important;

    .ant-modal-close {
      left: 0 !important;

      &-x {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-wrap {
    z-index: 1000;
  }

  &-mask {
    z-index: 999;
  }

  &-header {
    border-radius: 16px 16px 0 0 !important;
    background-color: rgba($primary, 0.02);

    .ant-modal-title {
      text-align: center;
    }
  }

  .ant-form-item {
    &-label {
      padding-bottom: 4px !important;
    }
  }
}

@import "../../styles/abstracts/variables";

.experience-layout {
  &__header.ant-layout-header {
    padding: 0 60px;
    background-color: $white;
    height: 88px;
    box-shadow: 0 1px 0 $border;
    margin-bottom: 1px;
  }

  &__content {
    background-color: $white;
  }
}

.ant-picker {
  width: 100%;

  input {
    padding: 0;
    border: none !important;
    height: auto !important;
    background-color: transparent !important;
  }

  &-footer {
    display: none;
  }

  &-suffix {
    height: 24px;
  }
}

.ant-picker-dropdown {
  z-index: 10001 !important;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  border-radius: 8px;

  .ant-picker-panel {
    border-radius: 8px;
  }
}

.ant-picker-calendar {
  &-date {
    margin: 0 !important;
    padding: 8px 8px 16px 12px !important;
    border: 1px solid $border !important;
    height: 100% !important;

    &-value {
      text-align: left;
      font-weight: 500;
      width: 28px;
      height: 28px;
      line-height: 28px !important;
      color: $default !important;
    }

    &-today {
      .ant-picker-calendar-date-value {
        background-color: $primary;
        border-radius: 100%;
        text-align: center;
      }
    }

    &-content {
      height: auto !important;
      min-height: 46px !important;
      overflow-y: visible !important;

      ul {
        padding: 0;

        span {
          font-size: 10px;
        }
      }
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-calendar-date {
      background-color: rgba(115, 229, 255, 0.2) !important;
    }
  }

  .ant-picker-cell {
    visibility: hidden;
    pointer-events: none;

    &.ant-picker-cell-in-view {
      visibility: visible;
      pointer-events: visible;
    }
  }

  .ant-picker-panel {
    margin-top: 24px;

    tr:last-child {
      .ant-picker-cell {
        display: none;
        max-width: calc(100% / 7);
      }

      .ant-picker-cell-in-view {
        display: block;
      }
    }

    .ant-picker-body {
      padding: 0;
    }
  }

  .ant-picker-content {
    display: flex;
    flex-direction: column;

    thead {
      margin-bottom: 24px;

      tr {
        display: flex;

        th {
          text-align: center;
          color: $default;
          font-weight: 500;
          text-transform: uppercase;
          flex: 1;
        }
      }
    }

    tbody {
      display: flex;
      flex-direction: column;

      &::before {
        content: "-";
        display: block;
        line-height: 24px;
        color: transparent;
      }

      tr {
        display: flex;

        td {
          flex: 1;
        }

        &:first-child {
          td {
            &:first-child {
              .ant-picker-calendar-date {
                border-radius: 8px 0 0 0;
              }
            }

            &:last-child {
              .ant-picker-calendar-date {
                border-radius: 0 8px 0 0;
              }
            }
          }
        }

        &:last-child {
          td {
            &:first-child {
              .ant-picker-calendar-date {
                border-radius: 0 0 0 8px;
              }
            }

            &:last-child {
              .ant-picker-calendar-date {
                border-radius: 0 0 8px 0;
              }
            }

            &.ant-picker-cell-disabled {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ant-picker-calendar {
    &-date {
      padding: 4px !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border: none !important;

      &-value {
        text-align: center;
      }
    }

    .ant-picker-cell-selected,
    .ant-picker-cell:hover {
      .ant-picker-calendar-date {
        background-color: $white !important;
      }
    }
  }

  .ant-picker-time-panel {
    &-column {
      width: 60px !important;
    }
  }
}

@import "../../styles/abstracts/variables";

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(116px, -50%);
  z-index: 9999;

  &--full {
    width: 100%;
    height: 100%;
    transform: translate(0, 0);
    left: 0;
    top: 0;
    background-color: $white;
  }
}

@media only screen and (max-width: 768px) {
  .loading {
    width: 100%;
    left: 0;
    transform: translate(0, -50%);
  }
}

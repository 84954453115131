.d {
  &--flex {
    display: flex !important;

    &--row {
      display: flex !important;
      flex-direction: row;
    }

    &--column {
      display: flex !important;
      flex-direction: column;
    }
  }

  &--block {
    display: block !important;
  }

  &--none {
    display: none !important;
  }
}

.align__items {
  &--center {
    align-items: center;
  }

  &--stretch {
    align-items: stretch;
  }

  &--start {
    align-items: flex-start;
  }

  &--end {
    align-items: flex-end;
  }
}

.justify__content {
  &--center {
    justify-content: center;
  }

  &--start {
    justify-content: flex-start;
  }

  &--end {
    justify-content: flex-end;
  }
}

.flex__direction {
  &--column {
    flex-direction: column;
  }
}

.position {
  &--relative {
    position: relative;
  }
}

.box-shadow {
  &--bottom {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.09);
  }
}
.borderless {
  border: 0 !important;
}
.opacity {
  &--50 {
    opacity: 0.5;
  }
  &--80 {
    opacity: 0.8;
  }
}

$total: 150;

@for $i from 0 through $total {
  // Margin
  .m__t--#{$i*2} {
    margin-top: #{$i * 2}px !important;
  }
  .m__b--#{$i*2} {
    margin-bottom: #{$i * 2}px !important;
  }
  .m__r--#{$i*2} {
    margin-right: #{$i * 2}px !important;
  }
  .m__l--#{$i*2} {
    margin-left: #{$i * 2}px !important;
  }
  .m__y--#{$i*2} {
    margin-top: #{$i * 2}px !important;
    margin-bottom: #{$i * 2}px !important;
  }
  .m__x--#{$i*2} {
    margin-left: #{$i * 2}px !important;
    margin-right: #{$i * 2}px !important;
  }
  .m--#{$i*2} {
    margin: #{$i * 2}px !important;
  }

  // Padding
  .p__t--#{$i*2} {
    padding-top: #{$i * 2}px !important;
  }
  .p__b--#{$i*2} {
    padding-bottom: #{$i * 2}px !important;
  }
  .p__r--#{$i*2} {
    padding-right: #{$i * 2}px !important;
  }
  .p__l--#{$i*2} {
    padding-left: #{$i * 2}px !important;
  }
  .p__y--#{$i*2} {
    padding-top: #{$i * 2}px !important;
    padding-bottom: #{$i * 2}px !important;
  }
  .p__x--#{$i*2} {
    padding-left: #{$i * 2}px !important;
    padding-right: #{$i * 2}px !important;
  }
  .p--#{$i*2} {
    padding: #{$i * 2}px !important;
  }

  // Top
  .top--#{$i*2} {
    top: #{$i * 2}px !important;
    bottom: initial !important;
  }

  // Bottom
  .bottom--#{$i*2} {
    bottom: #{$i * 2}px !important;
    top: initial !important;
  }

  // Right
  .right--#{$i*2} {
    right: #{$i * 2}px !important;
    left: initial !important;
  }

  // Left
  .left--#{$i*2} {
    left: #{$i * 2}px !important;
    right: initial !important;
  }
  .w--#{$i*2} {
    width: #{$i * 2}px !important;
  }
  .h--#{$i*2} {
    height: #{$i * 2}px !important;
  }
}
.leading {
  &-32 {
    line-height: 32px !important;
  }
}
.m {
  &__x--auto {
    margin: 0 auto !important;
  }
}

.p {
  &--0 {
    padding: 0 !important;
  }
}

.full-height {
  height: 100% !important;
}

.full-width {
  width: 100% !important;
}
.screen-width {
  width: 100vw !important;
}
.screen-height {
  height: 100vh !important;
}

.flex--1 {
  flex: 1;
}

@import "../abstracts/variables";

button.ant-btn {
  border-radius: 8px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-weight: 600;
  &.ant-btn-lg {
    height: 52px !important;
  }
  &.ant-btn-text {
    border: none !important;
    &:hover {
      background-color: transparent;
      opacity: 0.8;
    }
    &[disabled] {
      background-color: transparent !important;
      border: none;
    }
  }

  svg ~ span {
    margin-left: 8px;
  }

  path {
    stroke: $default;
  }

  &.full-width {
    span {
      width: 100%;
    }
  }

  i ~ span {
    margin-left: 12px;
  }

  &-primary {
    color: $default;

    &:focus,
    &:hover,
    &:active {
      color: $default;
    }
  }

  &-round {
    border-radius: 60px;
  }

  &.btn--primary {
    border: 1px solid $primary;
    color: $default;
  }

  &.btn--primary--background {
    color: $default;
    background-color: rgba($color: $primary, $alpha: 0.1);
    border: none;
  }

  &.btn--blue {
    border: 1px solid $blue;
    color: $blue;
  }

  &.btn--blue--background {
    color: $blue;
    background-color: rgba($color: $blue, $alpha: 0.1);
    border: none;
  }

  &.btn--green--background--outline {
    border: 1px solid $blue;
    color: $blue;
    background-color: rgba($color: $blue, $alpha: 0.1);
  }

  &.btn--orange {
    border: 1px solid $karry;
    color: $orange;
  }

  &.btn--orange--background {
    color: $orange;
    background-color: rgba($color: $orange, $alpha: 0.1);
    border: none;
  }

  &.btn--red {
    border: 1px solid rgba($color: $red, $alpha: 0.1);
    color: $red;
  }

  &-dangerous {
    border: none !important;
    color: $white !important;

    svg {
      path {
        stroke: $red;
      }
    }
  }

  &.btn--red--background {
    color: $red;
    background-color: rgba($color: $red, $alpha: 0.1);
    border: none;
  }

  &.btn--square {
    width: 48px;
    height: 48px;
    padding: 0 2.5px;

    span {
      display: block;
      font-size: 10px;
      word-wrap: break-word;
      white-space: pre-line;
    }
  }
}

.ant-switch {
  height: 24px;
  background-color: $gray;

  &-handle {
    width: 20px;
    height: 20px;

    &::before {
      background-color: rgba($default, 0.4);
      box-shadow: none;
    }
  }

  &-checked {
    background-color: $primary;

    .ant-switch-handle {
      left: calc(100% - 20px - 2px);

      &::before {
        background-color: $white;
      }
    }
  }
}

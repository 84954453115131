@import "./abstracts/variables";
@import "./base/background";
@import "./base/page";
@import "./base/display";
@import "./base/button";
@import "./base/spacing";
@import "./base/text";
@import "./base/table";
@import "./base/form";
@import "./base/modal";
@import "./base/checkbox";
@import "./base/calendar";

.cursor-pointer {
  cursor: pointer !important;
}

// Background Color
.bg {
  &--primary {
    background-color: $primary;
  }

  &--blue {
    background-color: $blue;
  }

  &--white {
    background-color: $white;
  }

  &--red {
    background-color: $red-light;
  }

  &--orange {
    background-color: $orange;
  }
}
